export const theme = {
  typography: {
    link: {
      textDecoration: 'none',
    },
  },
  button: {
    primary: {
      fontWeight: 600,
    },
  },
  formHelper: {
    helptext: {
      display: 'inline-block',
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: '1.125rem',
      letterSpacing: '-0.01em',
      color: 'hsla(285, 0%, 29%, 1)',
    },
  },
};
export default theme;
