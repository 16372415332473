import { ResultSeverity } from '../types/__generated__/globalTypes';

export const MYRISK_NAME = 'MyRisk™';
export const MYRISK_NAME_NO_TRADEMARK = 'MyRisk';

export const RESULT_SEVERITIES = [
  ResultSeverity.HIGH_RISK,
  ResultSeverity.ELEVATED_RISK,
  ResultSeverity.POSSIBLE_RISK,
  ResultSeverity.INCONCLUSIVE,
  ResultSeverity.NEGATIVE,
];

export const FILENAME_FALLBACK = 'MyRiskGeneticResult';
