import { Link as RouterLink } from 'react-router-dom';
import { Button, Icon } from 'primase';
import { CSSObject } from '@emotion/react';

const linkStyles = {
  textDecoration: 'none',
};

const buttonStyles = {
  srOnly: {
    clip: 'rect(1px, 1px, 1px, 1px)',
    clipPath: 'inset(50%)',
    height: 1,
    width: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute' as const,
  },
};

interface ILinkProps {
  children?: React.ReactNode;
  styles?: any;
  to: string | { pathname: string; search?: string; state?: unknown };
  routerLinkStyles?: React.CSSProperties;
}

type ButtonProps = {
  primary?: boolean;
  secondary?: boolean;
  style?: React.CSSProperties;
  css?: CSSObject;
};

interface IAProps {
  children?: React.ReactNode;
  styles?: any;
  target?: string;
  onClick?: () => void;
  to: string;
  download?: string;
  routerLinkStyles?: React.CSSProperties;
  rel?: string;
}

export const Link = ({ children, styles = {}, to, ...rest }: ILinkProps) => (
  <RouterLink to={to} style={{ ...linkStyles, ...styles }} {...rest}>
    {children}
  </RouterLink>
);

Link.defaultProps = {
  children: null,
  styles: null,
};

export const LinkButton = ({
  children,
  to,
  buttonProps,
  routerLinkStyles,
  ...rest
}: ILinkProps & { buttonProps?: ButtonProps }) => (
  <RouterLink
    to={to}
    style={{
      ...linkStyles,
      ...routerLinkStyles,
      margin: 'auto',
      padding: 2,
      borderRadius: 4,
    }}
    {...rest}
  >
    <span style={buttonStyles.srOnly}>{children}</span>
    <Button tabIndex={-1} aria-hidden="true" {...buttonProps}>
      {children}
    </Button>
  </RouterLink>
);

export const A = ({ children, styles = {}, to, ...rest }: IAProps) => (
  <a href={to} style={{ ...linkStyles, ...styles }} {...rest}>
    {children}
  </a>
);

A.defaultProps = {
  children: null,
  styles: null,
};

type AButtonProps = {
  buttonProps?: ButtonProps;
  children?: React.ReactNode;
  download?: string;
  rel?: string;
  target?: string;
  to: string;
  routerLinkStyles?: React.CSSProperties;
  disabled?: boolean;
};

export const AButton = ({
  buttonProps,
  children,
  routerLinkStyles,
  to,
  disabled = false,
  ...rest
}: AButtonProps) => {
  if (disabled) {
    return (
      <Button disabled tabIndex={-1} aria-hidden="true" {...buttonProps}>
        {children}
        <Icon>download</Icon>
      </Button>
    );
  }

  return (
    <A
      to={to}
      styles={{
        ...linkStyles,
        ...routerLinkStyles,
        borderRadius: 4,
        margin: 'auto',
        padding: 2,
      }}
      {...rest}
    >
      <span style={buttonStyles.srOnly}>{children}</span>
      <Button tabIndex={-1} aria-hidden="true" {...buttonProps}>
        {children}
        <Icon>download</Icon>
      </Button>
    </A>
  );
};

AButton.defaultProps = {
  children: null,
  rel: 'noreferrer',
  target: '_blank',
};
