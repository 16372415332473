import { makeVar } from '@apollo/client';

/**
 * UserInfo reactive variable
 * It is populated with user data on success auth event
 * We can access it from anywhere in our application
 */
type UserInfo = {
  username?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
};

export const userInfoVar = makeVar<UserInfo>({});

/**
 * Toasts reactive variable
 * We need only one toast notification at a time
 * so we use this variable to keep recent toast notification
 * IMPORTANT: toasts will be removed automatically on navigation between pages unless you set /persist/ field for toast
 */
export type Toast = {
  type: 'error' | 'success' | 'info-dark';
  message: string;
  /** Mininum number of milliseconds that the toast will be visible.
   * Ignored when `persist` is enabled.
   */
  timeout?: number;
  /** Prevents default timeout from removing the toast */
  persist?: boolean;
  /* Error details for Sentry reporting, not shown in UI */
  detail?: unknown;
} | null;
export const toastVar = makeVar<Toast>(null);

/**
 * Access token reactive variable
 * For security purpose we keep access token in memory and use silent refresh
 * flow on each app start to get new access token using refresh token (it stored in secured httpOnly cookies)
 */
export const tokenVar = makeVar<string | null | undefined>(null);

// This is used to optimize the lookup of whether or not impersonation is active
export const impersonationVar = makeVar<boolean | null>(null);
