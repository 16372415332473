/** Get LS item */
export const getLocalStorageItem = (key: string, initialValue: any) => {
  try {
    // Get from local storage by key
    const item = window.localStorage.getItem(key);

    // Parse stored json or if none return initialValue
    return item && item !== 'undefined' ? JSON.parse(item) : initialValue;
  } catch (e) {
    // If error also return initialValue
    // eslint-disable-next-line no-console
    console.log(e);

    return initialValue;
  }
};

/** Set LS item */
export const setLocalStorageItem = (key: string, value: any) => {
  try {
    // Save to local storage
    window.localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    // A more advanced implementation would handle the error case
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

// Create key for LS entry
export const formatLocalStorageKey = (label: string, id: string) =>
  `${label}-${id}`;
