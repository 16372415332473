import { useTranslation } from 'react-i18next';
import { PAGES } from '../../constants/pages';
import { REACT_APP_CONTACT_US_URL } from '../../constants/support';

export const useTranslatedLinks = () => {
  const { t } = useTranslation();

  const links = [
    {
      title: t('pages.myTests'),
      href: `${PAGES.MY}${PAGES.TESTS}`,
    },
    {
      title: t('pages.myConsults'),
      href: `${PAGES.MY}${PAGES.CONSULTS}`,
    },
    {
      title: t('labels.support'),
      href: {
        pathname: REACT_APP_CONTACT_US_URL,
        search: '',
      },
      target: '_blank',
    },
  ];

  return { links };
};

export const openInNewTab = (url: string): void => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');

  if (newWindow) {
    newWindow.opener = null;
  }
};
