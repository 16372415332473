import { ApolloClient, from, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import * as Sentry from '@sentry/react';

import { cache } from './cache';
import { getToken, setTokens } from './account/helpers';

/**
 * HTTP link
 * We have to use it as a last link!
 */
const httpLink = createHttpLink({
  uri: window.env.REACT_APP_GQL_API_ENDPOINT,
});

/**
 * Auth link
 * Read latest token from reactive variable and inject corresponding headers to any request
 */
const authLink = setContext((_, { headers }) => {
  // get the access token from tokensVar reactive variable
  const token = getToken();

  // return the headers to the context so httpLink can read them
  return {
    credentials: 'include',
    headers: {
      ...headers,
      Authorization: token ? `JWT ${token}` : null,
    },
  };
});

/**
 * Error link
 * clear token storage and redirect user
 */
const errorLink = onError(({ graphQLErrors, operation, forward }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((err) => {
      if (
        err.message.includes('AnonymousUser') ||
        err.message.includes('Signature has expired')
      ) {
        // todo
        // try refresh token...
        // retry
        // if failed

        setTokens(null);

        // eslint-disable-next-line no-restricted-globals
        location.reload();
      } else {
        const operationInfo = {
          query: operation.query,
          variables: operation.variables,
          operationName: operation.operationName,
        };

        Sentry.captureException(err, { extra: operationInfo });
      }
    });
  }

  return forward(operation);
});

/**
 * Set up ApolloClient
 */
export const client = new ApolloClient({
  cache,
  link: from([authLink, errorLink, httpLink]),
});
