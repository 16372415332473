import { Styles } from 'primase';
import {
  HEADER_MOBILE_HEIGHT,
  HEADER_DESKTOP_HEIGHT,
  FOOTER_DESKTOP_HEIGHT,
} from './constants';

const SIZE_LARGE = Styles.spacing.sp1 * 22;
const BREAKPOINT_SM = `@media screen and (max-width: ${Styles.layout.Breakpoints.sm}px)`;

export const styles = {
  container: {
    minHeight: '100vh',
    backgroundColor: Styles.color.neutral.neutral2,
    [`@media (max-width: ${Styles.layout.Breakpoints.sm}px)`]: {
      backgroundColor: Styles.color.neutral.white,
    },
  },
  header: {
    backgroundColor: Styles.color.neutral.white,
    zIndex: 1,
  },
  background: {
    minHeight: `${SIZE_LARGE}px`,
    marginBottom: `-${SIZE_LARGE - Styles.spacing.sp1 * 3}px`,
  },
  content: {
    minHeight: `calc(100vh - ${HEADER_DESKTOP_HEIGHT}px - ${FOOTER_DESKTOP_HEIGHT}px)`,
    padding: `0px ${Styles.spacing.sp6}px ${Styles.spacing.sp8}px`,
    [`@media (max-width: ${Styles.layout.Breakpoints.md}px)`]: {},
    [`@media (max-width: ${Styles.layout.Breakpoints.sm}px)`]: {
      padding: 0,
      minHeight: `calc(100vh - ${HEADER_MOBILE_HEIGHT}px)`,
    },
  },
  footer: {
    position: 'absolute',
    left: '0',
    bottom: '0',
    width: '100%',
    [`@media (max-width: ${Styles.layout.Breakpoints.sm}px)`]: {
      marginTop: '80px',
      position: 'relative',
    },
  },
  title: {
    marginTop: 0,
    [BREAKPOINT_SM]: {
      fontSize: '24px',
      padding: '15px 0px 0px 24px',
      fontWeight: '600',
      marginBottom: '0px',
    },
    paddingBottom: '10px',
    fontWeight: '500',
    fontSize: '40px',
    marginBottom: '3px',
  },
  titleText: {
    fontWeight: '700',
    fontSize: '40px',
    letterSpacing: '-0.01em',
    [`@media screen and (max-width: ${Styles.layout.Breakpoints.sm}px)`]: {
      fontSize: '22px',
      fontWeight: '800',
    },
  },
  subTitle: {
    marginTop: Styles.spacing.sp1,
  },
  colonTitle: {
    fontWeight: 700,
    fontSize: '13px',
    textTransform: 'uppercase',
    color: Styles.color.gray[500],
    marginBottom: Styles.spacing.sp2,
  },
  wrapper: {
    maxWidth: '100%',
    width: 1032,
    margin: '40px auto',
    [BREAKPOINT_SM]: {
      margin: '0 auto',
    },
  },
};

export default styles;
