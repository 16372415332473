const PAGES = {
  BASE: '/',
  MY: '/my',
  ONBOARDING: '/onboarding',
  INTRO: '/intro',
  SETTINGS: '/settings',
  WELCOME: '/welcome',
  VERIFY_IDENTITY: '/verify-identity',
  GET_STARTED: '/get-started',
  BY_PERSONAL_INFO: '/by-personal-info',
  BY_PHONE: '/by-phone',
  ACTIVATE_ACCOUNT: '/activate',
  REQUEST_VERIFICATION_CODE: '/request-verification-code',
  VALIDATE_VERIFICATION_CODE: '/validate-verification-code',
  TESTS: '/tests',
  RESULTS: '/results',
  PRE_RESULT_ACKNOWLEDGEMENT: '/pre-result-acknowledgement',
  CONSULTS: '/consults',
  BILLING: '/billing',
  ORDER: '/order',
  ORDERS: '/orders',
  NEW: '/new',
  ACCOUNT: '/account',
  LOGIN: '/login',
  REGISTER: '/register',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  CHANGE_PASSWORD: '/change-password',
  SCHEDULE: '/schedule',
  ON_DEMAND: '/on-demand',
  ESTIMATES: '/estimates',
  COST_AGREEMENT: '/cost-agreement',
  PAY_NOW: '/pay-now',
  UPDATE_INSURANCE: '/update-insurance',
  LOADER: '/loader',
  IMPERSONATE: '/impersonate',
};

const NEW_ORDER_WIZARD_STEPS = {
  DOCTOR: 'doctor',
  PATIENT: 'patient',
  SHIPPING: 'shipping',
  BILLING: 'billing',
  REVIEW: 'review',
};

const NEW_CONSULT_SCHEDULE_WIZARD_STEPS = {
  PARTICIPANT: 'participant',
  SCHEDULE: 'schedule',
};

export { PAGES, NEW_ORDER_WIZARD_STEPS, NEW_CONSULT_SCHEDULE_WIZARD_STEPS };
export default PAGES;
