import { ReactNode } from 'react';
import { Text, View } from 'primase';
import { useMediaQuery } from 'react-responsive';

// Styles
import { styles } from './styles';

interface IPageContentProps {
  title?: JSX.Element | string;
  titleStyles?: Record<string, unknown>;
  children?: ReactNode;
  bodyStyles?: Record<string, unknown> | null;
}

const PageContent = ({
  title,
  titleStyles,
  children,
  bodyStyles,
}: IPageContentProps) => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <View
      classes={
        bodyStyles ? { ...bodyStyles, ...styles.wrapper } : styles.wrapper
      }
    >
      {title && (
        <View
          classes={
            titleStyles ? { ...styles.title, ...titleStyles } : styles.title
          }
        >
          <Text
            role="heading"
            aria-level="1"
            classes={styles.titleText}
            type={isMobile ? 'h2' : 'h1'}
          >
            {title}
          </Text>
        </View>
      )}
      {children}
    </View>
  );
};

PageContent.defaultProps = {
  title: null,
  children: null,
  titleStyles: null,
};

export default PageContent;
