import { Styles } from 'primase';

export const styles = {
  menuItem: {},
  menuLink: {
    display: 'block',
    color: Styles.color.neutral.black,
    width: '100%',
    height: '100%',
    padding: '11px 16px',
  },
  mobileNav: {
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  registerLink: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: `${Styles.spacing.sp4}px`,
  },
  superscript: {
    marginTop: -4,
    fontSize: 8,
    '.ie &': {
      fontSize: 10,
    },
  },
  link: {
    textDecoration: 'none',
  },
  logoWrapper: {
    padding: `28px 0`,
    [`@media (max-width: ${Styles.layout.Breakpoints.md}px)`]: {
      padding: '15px 0',
    },
  },
  logo: {
    height: 23,
    width: 140,
    marginLeft: 20,
  },
  navTabLink: {
    fontSize: '14px',
    color: Styles.color.primary.primary7,
    padding: `0 ${Styles.spacing.sp4}px`,
  },
  navTabWrapper: {
    default: {
      padding: `30px ${Styles.spacing.sp4}px`,
    },
  },
  navTabText: {
    fontSize: '14px',
    color: Styles.color.neutral.neutral6,
  },
};

export default styles;
