import { Redirect, RedirectProps } from 'react-router-dom';

// Components
import Loader from '../loader';

type BaseRedirectProps = {
  to: RedirectProps['to'];
};

// When running react-snap, capture base path (renders loading screen) for index.html
export const BaseRedirect = ({ to }: BaseRedirectProps) => {
  if (navigator.userAgent === 'ReactSnap') {
    return <Loader fullScreen />;
  }

  return <Redirect to={to} />;
};
