import { useQuery } from '@apollo/client';
import jwtDecode from 'jwt-decode';
import { useMemo } from 'react';
import Cookies from 'universal-cookie';

import {
  getLocalStorageItem,
  setLocalStorageItem,
} from '../../helpers/local-storage';
import { tokenVar, impersonationVar } from '../globals';
import { USERINFO_QUERY } from './queries';

/**
 * Tokens related helpers
 */
export const LS_TOKEN_NAME = 'PP2RefreshToken';

/**
 * Checks if token espired
 * @param exp - number
 */
export const isExpiredToken = (exp: number): boolean =>
  Date.now() >= exp * 1000;

/**
 * Temp function to get refreshToken until cookies will be ready
 */
export const getTokenLocalStorage = () =>
  getLocalStorageItem(LS_TOKEN_NAME, null);

/**
 * Temp function to store refreshToken until cookies will be ready
 */
export const setTokens = (token: string | null) => {
  setLocalStorageItem(LS_TOKEN_NAME, token);
};

/**
 * Sets token to reactiveVar
 * @param token - Access Token string
 */
export const setToken = (token: string | null) => {
  tokenVar(token);
};

/**
 * Gets Access Token from reactiveVar
 */
export const getToken = () => tokenVar();

/**
 * @returns date of token expiration
 */
export const getTokenExpiration = () => {
  const token = getToken() ?? getTokenLocalStorage();

  if (!token) return false;

  const decoded: { exp: number } = jwtDecode(token);

  return decoded.exp * 1000;
};

/**
 * Sets isImpersonating to reactiveVar
 * @param isImpersonating - Boolean
 */
export const setIsImpersonatingActive = (isImpersonating = false) => {
  impersonationVar(isImpersonating);

  const impersonationCookies = new Cookies(null, {
    path: '/',
    maxAge: 60 * 30, // End impersonation after 30 minutes
    sameSite: 'strict',
  });

  impersonationCookies.set('impersonating', isImpersonating);
};

/**
 * Gets Access Token from reactiveVar which is a much more efficient lookup than cookies
 * The isImpersonatingCookie is used as a fallback since
 * the reactiveVar does not persist across tabs or refreshes
 * @param useReactiveVar - Boolean, sometimes we only want to query the cookie (to see if it expired)
 */
export const isImpersonatingActive = (useReactiveVar = true) => {
  const impersonationCookies = new Cookies(null, {
    sameSite: 'strict',
  });

  if (useReactiveVar) {
    if (impersonationVar()) {
      return true;
    }

    if (impersonationVar() === null) {
      impersonationVar(impersonationCookies.get('impersonating') ?? false);
    }

    return impersonationVar();
  }

  return impersonationCookies.get('impersonating');
};

export const getUnimpersonateURL = () => {
  const impersonationCookies = new Cookies(null, {
    path: '/',
    sameSite: 'strict',
  });

  return impersonationCookies.get('end_impersonation_return_url');
};

/**
 * Validates token
 */
export const isTokenValid = () => {
  const token = getToken() ?? getTokenLocalStorage();

  if (!token) return false;

  const decoded: { exp: number } = jwtDecode(token);

  const isExpired = isExpiredToken(decoded.exp);

  return !isExpired;
};

/**
 * Calculate silent token refresh period
 * @param token - access token string
 */
export const getTokenRefreshInterval = (token: string) => {
  try {
    const decoded: { exp: number } = jwtDecode(token);

    const tokenDate = decoded.exp * 1000;

    const now = Date.now();

    // Refresh 60 seconds before access token expires
    const diff = tokenDate - now - 60 * 1000;

    return diff > 0 ? diff : 0;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Failed to decode access_token', e);

    return 0;
  }
};

export const useUserInfo = (isAuthenticated: boolean) => {
  const { data } = useQuery(USERINFO_QUERY, {
    skip: !isAuthenticated,
    fetchPolicy: 'cache-first',
  });
  const user = useMemo(
    () =>
      data?.account?.profile
        ? {
            firstName: data.account.profile.firstName!,
            lastName: data.account.profile.lastName!,
            email: data.account.email!,
          }
        : null,
    [data?.account],
  );

  return user;
};
