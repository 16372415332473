import { gql, TypedDocumentNode } from '@apollo/client/core';
import {
  ChangePassword,
  ChangePasswordVariables,
} from './__generated__/ChangePassword';
import { Login, LoginVariables } from './__generated__/Login';
import { RefreshToken } from './__generated__/RefreshToken';
import { Register, RegisterVariables } from './__generated__/Register';
import {
  ResetPassword,
  ResetPasswordVariables,
} from './__generated__/ResetPassword';
import { UserInfo } from './__generated__/UserInfo';

export const LOGIN_QUERY = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      __typename
      ... on LoginSuccess {
        account {
          email
          profile {
            firstName
            lastName
          }
        }
        token
      }
    }
  }
` as TypedDocumentNode<Login, LoginVariables>;

export const LOGOUT_QUERY = gql`
  mutation Logout {
    logout {
      __typename
    }
  }
`;

export const REGISTER_QUERY = gql`
  mutation Register(
    $firstName: String!
    $lastName: String!
    $email: String!
    $password: String!
  ) {
    register(
      email: $email
      firstName: $firstName
      lastName: $lastName
      password: $password
    ) {
      ... on RegisterSuccess {
        account {
          email
          profile {
            firstName
            lastName
          }
        }
        token
      }
      ... on EmailInvalid {
        issue
      }
    }
  }
` as TypedDocumentNode<Register, RegisterVariables>;

export const USERINFO_QUERY = gql`
  query UserInfo {
    account {
      profile {
        firstName
        lastName
      }
      email
    }
  }
` as TypedDocumentNode<UserInfo, undefined>;

// todo - update this mutation when token will be fetched from cookies
export const REFRESH_MUTATION = gql`
  mutation RefreshToken {
    refreshToken {
      token
    }
  }
` as TypedDocumentNode<RefreshToken>;

export const CHANGE_PASSWORD_MUTATION = gql`
  mutation ChangePassword($newPassword: String!, $oldPassword: String!) {
    changePassword(newPassword: $newPassword, oldPassword: $oldPassword) {
      __typename
    }
  }
` as TypedDocumentNode<ChangePassword, ChangePasswordVariables>;

export const REQUEST_RESET_PASSWORD_MUTATION = gql`
  mutation RequestResetPassword($email: String!) {
    requestResetPassword(email: $email) {
      __typename
    }
  }
`;

export const RESET_PASSWORD_MUTATION = gql`
  mutation ResetPassword($key: String!, $password: String!) {
    resetPassword(key: $key, password: $password) {
      __typename
    }
  }
` as TypedDocumentNode<ResetPassword, ResetPasswordVariables>;
