import { Styles } from 'primase';

export const styles = {
  footerLegalText: {
    fontSize: '0.6875rem',
    lineHeight: '0.9rem',
    color: 'rgba(33, 31, 61, 0.5)',
    textAlign: 'left',
    [`@media (max-width: ${Styles.layout.Breakpoints.md}px)`]: {
      textAlign: 'center',
    },
  },
  centerText: {
    textAlign: 'center',
  },
  mobileFooterLegalTextWrapper: {
    borderTop: 'solid 1px #0071CE',
    padding: '10px 5px 15px 5px',
  },
  footerLinkText: {
    fontWeight: 500,
    color: '#211F3D',
    fontSize: '13px',
    letterSpacing: '-0.03em',
  },
  desktopLinkWrapper: {
    '> div:first-of-type': {
      padding: 0,
    },
    alignItems: 'center',
  },
  desktopLinksContainer: {
    padding: 0,
    margin: '0 !important',
  },
  desktopFooterContainer: {
    maxWidth: '100vw',
    minWidth: '100%',
    borderTop: 'solid 1px #0071CE',
    paddingLeft: '20px',
    margin: '0 !important',
  },
  mobileLinkWrapper: {
    minWidth: '95px',
    height: '35px',
  },
};
