import React from 'react';
import { View, Text, Grid } from 'primase';
import { useTranslation, Trans } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

// Constants
import {
  REACT_APP_MYRIAD_PRIVACY_POLICY_URL,
  REACT_APP_MYRIAD_TOS_URL,
} from '../../constants/links';
import { REACT_APP_CONTACT_US_URL } from '../../constants/support';

// Styles
import { styles } from './styles';

// Components
import { A } from '../link';

export const AppFooter = () => {
  const { t } = useTranslation();
  const isDesktop = useMediaQuery({ query: '(min-width: 980px)' });

  const TOS = (
    <A styles={styles.centerText} to={REACT_APP_MYRIAD_TOS_URL} target="_blank">
      <Text css={styles.footerLinkText}>{t('labels.termsOfUse')}</Text>
    </A>
  );
  const privacy = (
    <A
      styles={styles.centerText}
      to={REACT_APP_MYRIAD_PRIVACY_POLICY_URL}
      target="_blank"
    >
      <Text css={styles.footerLinkText}>{t('labels.privacy')}</Text>
    </A>
  );
  const contactUs = (
    <A styles={styles.centerText} to={REACT_APP_CONTACT_US_URL} target="_blank">
      <Text css={styles.footerLinkText}>{t('labels.contactUs')}</Text>
    </A>
  );

  const legalText = (
    <Text css={styles.footerLegalText}>
      <Trans
        i18nKey="labels.footerCopyright"
        components={[<sup>&reg;</sup>]}
        values={{ year: new Date().getFullYear() }}
      />
    </Text>
  );

  const desktopFooter = (
    <Grid container css={styles.desktopFooterContainer}>
      <Grid item md={9} lg={9}>
        {legalText}
      </Grid>
      <Grid item md={3} lg={3}>
        <Grid container css={styles.desktopLinksContainer}>
          <Grid item css={styles.desktopLinkWrapper} md={4} lg={4}>
            {TOS}
          </Grid>
          <Grid item css={styles.desktopLinkWrapper} md={4} lg={4}>
            {privacy}
          </Grid>
          <Grid item css={styles.desktopLinkWrapper} md={4} lg={4}>
            {contactUs}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const mobileFooter = (
    <>
      <View row css={{ margin: '0 auto' }}>
        <View inline css={styles.mobileLinkWrapper}>
          {TOS}
        </View>
        <View inline css={styles.mobileLinkWrapper}>
          {privacy}
        </View>
        <View inline css={styles.mobileLinkWrapper}>
          {contactUs}
        </View>
      </View>
      <View css={styles.mobileFooterLegalTextWrapper}>{legalText}</View>
    </>
  );

  return (
    <View data-testid="app-footer__wrapper">
      {isDesktop ? desktopFooter : mobileFooter}
    </View>
  );
};

export default React.memo(AppFooter);
