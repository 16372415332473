import { Styles } from 'primase';

export const styles = {
  icon: {
    // ...Styles.typography.variants.h3,
    fontSize: '1.5rem',
    ...Styles.typography.link,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    textDecoration: 'none',
  },
  profile: {
    color: Styles.color.gray[600],
    cursor: 'pointer',
    paddingLeft: Styles.spacing.sp1,
  },
  profileWrapper: {
    color: Styles.color.neutral.black,
    border: `1px solid ${Styles.color.neutral.neutral3}`,
  },
  menuLink: {
    display: 'block',
    textDecoration: 'none',
    color: Styles.color.neutral.black,
    padding: '11px 16px',
  },
};

export default styles;
