import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

if (process.env.NODE_ENV !== 'development') {
  /**
   * Sentry monitoring config
   */
  Sentry.init({
    dsn: window.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // TODO: We recommend adjusting this value in production
    // tracesSampleRate: process.env.NODE_ENV === 'production' ? 0.7 : 1.0,
    tracesSampleRate: 1.0,
  });
}
