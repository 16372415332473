import { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { Styles } from 'primase';
import { FormikState, FormikHelpers, FormikValues } from 'formik';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import QueryString from 'qs';
import * as Sentry from '@sentry/react';

import { toastVar, Toast } from '../config/globals';
import i18n from '../i18n';
import { MYRISK_NAME, MYRISK_NAME_NO_TRADEMARK } from '../constants/results';

/**
 * Handle clickoutside
 * @param allowedRef
 */
export function useOnClickOutside(allowedRef?: any) {
  const containerRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleClickOutside = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  useEffect(() => {
    /** Listener */
    const listener = (event: Event) => {
      // Do nothing if clicking ref's element or descendent elements
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (
        !containerRef.current ||
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        containerRef?.current?.contains(event.target)
      ) {
        return;
      }

      if (allowedRef) {
        if (
          !allowedRef.current ||
          allowedRef.current?.contains?.(event.target)
        ) {
          return;
        }
      }

      handleClickOutside();
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [containerRef, handleClickOutside, allowedRef]);

  return {
    ref: containerRef,
    open,
    setOpen,
  };
}

export function useToasts() {
  const addToast = useCallback((toast: Toast) => {
    if (toast?.detail) {
      Sentry.captureException(toast.detail);
    }

    toastVar(toast);
  }, []);

  const removeToast = useCallback(() => {
    toastVar(null);
  }, []);

  return { addToast, removeToast };
}

export function useClearField<T extends FormikValues>(
  field: keyof T,
  condition: boolean,
  formik: FormikState<T> & FormikHelpers<T>,
) {
  useEffect(() => {
    if (condition === true) {
      formik.setFieldValue(`${field}`, undefined);
      formik.setFieldTouched(`${field}`, false);
    }
    // using `formik` as dependency causes loop
    // eslint-disable-next-line
  }, [field, condition]);

  return null;
}

export function useQueryParams() {
  const { search } = useLocation();
  const params = useMemo(
    () => QueryString.parse(search, { ignoreQueryPrefix: true }),
    [search],
  );

  return params;
}

export function useFormattedProductName(
  productName: string | null,
  term: 'Screening' | 'Test',
  trademark = true,
): string {
  if (
    productName?.toLowerCase().includes('myrisk') ||
    productName?.toLowerCase().includes('bracanalysis cdx')
  ) {
    if (term === 'Screening') {
      return i18n.t('results.myRiskScreening', {
        product: trademark ? MYRISK_NAME : MYRISK_NAME_NO_TRADEMARK,
      });
    }

    if (term === 'Test') {
      return i18n.t('results.myRiskTest', {
        product: trademark ? MYRISK_NAME : MYRISK_NAME_NO_TRADEMARK,
      });
    }
  }

  return productName ?? MYRISK_NAME;
}

export function useViewportWidth() {
  const xs = useMediaQuery({
    query: `(max-width: ${Styles.layout.Breakpoints.xs}px)`,
  });
  const sm = useMediaQuery({
    query: `(max-width: ${Styles.layout.Breakpoints.sm}px)`,
  });
  const md = useMediaQuery({
    query: `(max-width: ${Styles.layout.Breakpoints.md}px)`,
  });
  const lg = useMediaQuery({
    query: `(max-width: ${Styles.layout.Breakpoints.lg}px)`,
  });
  const xl = useMediaQuery({
    query: `(max-width: ${Styles.layout.Breakpoints.xl}px)`,
  });

  return { xs, sm, md, lg, xl };
}

export function useFeatureFlags() {
  return {
    prenatalResultsEnabled: window.env.PRENATAL_RESULTS_ENABLED === 'true',
  };
}
