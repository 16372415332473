import React, { useCallback, useEffect, useRef } from 'react';
import { Styles, View, Notification } from 'primase';
import { useReactiveVar } from '@apollo/client';
import Sticky from 'react-sticky-el';

// Constants
import { DEFAULT_TOAST_TIMEOUT } from './constants';

// Helpers
import { Toast, toastVar } from '../../config/globals';

// Components
import FadeInWrapper from '../fade-in-wrapper';

const styles = {
  zIndex1000: {
    zIndex: 1000,
  },
  container: {
    width: '100%',
    padding: `0 ${Styles.spacing.sp6}px`,
    [`@media (max-width: ${Styles.layout.Breakpoints.sm}px)`]: {
      padding: `0px ${Styles.spacing.sp2}px`,
    },
    height: 0,
  },
  wrapper: {
    maxWidth: '100%',
    width: 1032,
    margin: '0 auto',
    padding: `0px`,
  },
  notification: {
    root: {
      width: '100%',
    },
  },
};

const ToastContainer = () => {
  const toast: Toast = useReactiveVar(toastVar);
  const previousToastRef = useRef<{
    toast: Toast;
    timeoutID: NodeJS.Timeout | null;
  }>({ toast: null, timeoutID: null });
  const type = toast?.type || previousToastRef.current.toast?.type;
  const message = toast?.message || previousToastRef.current.toast?.message;

  const handleRemove = useCallback(() => {
    toastVar(null);
  }, []);

  useEffect(() => {
    // Clear previous timeout before adding new toast
    if (previousToastRef.current.timeoutID !== null) {
      clearTimeout(previousToastRef.current.timeoutID);
      previousToastRef.current.timeoutID = null;
    }

    // Only remove non-persisting toasts after 5 seconds
    if (toast && !toast.persist) {
      previousToastRef.current.toast = toast;
      previousToastRef.current.timeoutID = setTimeout(() => {
        handleRemove();
      }, toast.timeout ?? DEFAULT_TOAST_TIMEOUT);
    }
  }, [toast, handleRemove]);

  return (
    <View style={styles.zIndex1000}>
      <Sticky>
        <View css={styles.container}>
          <View css={styles.wrapper}>
            <FadeInWrapper show={!!toast}>
              <Notification
                type={type}
                classes={styles.notification}
                remove
                onRemove={handleRemove}
                data-testid="toast-notification"
                role="status"
                aria-live="polite"
              >
                {message}
              </Notification>
            </FadeInWrapper>
          </View>
        </View>
      </Sticky>
    </View>
  );
};

export default React.memo(ToastContainer);
