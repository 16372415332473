import { ReactNode } from 'react';
import { View } from 'primase';
import loadable from '@loadable/component';

// Components
import ToastContainer from '../toast';
import SupportLink from '../support-link';

// Helpers
import { useAccount } from '../../config/account';

// Styles
import { styles } from './styles';

const ImpersonationBanner = loadable(
  () =>
    import(
      '../../features/account/components/impersonation/impersonation-banner/impersonation-banner'
    ),
);

interface IPageLayoutProps {
  header?: ReactNode;
  children: ReactNode;
  footer?: ReactNode;
  headerClasses?: Record<string, unknown>;
  contentClasses?: Record<string, unknown>;
  footerClasses?: Record<string, unknown>;
}

const PageLayout = ({
  header,
  children,
  footer,
  headerClasses,
  contentClasses,
  footerClasses,
}: IPageLayoutProps) => {
  const { isAuthenticated, isImpersonating } = useAccount();

  const showImpersonationBanner = isAuthenticated() && isImpersonating();

  return (
    <View classes={styles.container}>
      <header>
        <nav aria-label="Main">
          <View
            id="application-header"
            css={
              headerClasses
                ? { ...styles.header, ...headerClasses }
                : styles.header
            }
          >
            {header}
          </View>
        </nav>
      </header>
      <ToastContainer />
      {showImpersonationBanner && <ImpersonationBanner />}
      <main>
        <View
          classes={
            contentClasses
              ? { ...styles.content, ...contentClasses }
              : styles.content
          }
        >
          {children}
        </View>
      </main>
      <footer>
        <View
          classes={
            footerClasses
              ? { ...styles.footer, ...footerClasses }
              : styles.footer
          }
        >
          <nav aria-label="Footer">
            <SupportLink />
          </nav>
          {footer}
        </View>
      </footer>
    </View>
  );
};

PageLayout.defaultProps = {
  header: null,
  footer: null,
  headerClasses: null,
  contentClasses: null,
  footerClasses: null,
};

export default PageLayout;
