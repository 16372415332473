import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import { Styles, Text, View, Navigation, MenuItem } from 'primase';
import { useTranslation } from 'react-i18next';

// Components
import Link, { A } from '../link';
import NavTab from './nav-tab';
import ProfileMenu from '../profile-menu';

// Images
import logo from '../../assets/images/myriad_V_horiz.png';

// Styles
import { styles } from './styles';

// Helpers
import { useTranslatedLinks, openInNewTab } from './helpers';
import { useAccount } from '../../config/account';

// Constants
import { PAGES } from '../../constants/pages';

const AppHeader = () => {
  const { isAuthenticated } = useAccount();
  const { pathname = '' } = useLocation();
  const history = useHistory();
  const { links } = useTranslatedLinks();
  const { t } = useTranslation();
  const areLinksAvailable = !pathname.includes(PAGES.ONBOARDING);

  const MyriadLogo = () => (
    <Link to={PAGES.MY} data-testid="app-header__logo-link">
      <View classes={styles.logoWrapper}>
        <img alt={t('labels.headLogoAltText')} src={logo} style={styles.logo} />
      </View>
    </Link>
  );

  const handleLinkClick = (
    href: string | { pathname: string; search: string },
  ) => {
    if (typeof href === 'string') {
      history.push(href);
    } else {
      openInNewTab(href.pathname);
    }
  };

  return (
    <View id="app-header__wrapper">
      <MediaQuery minWidth={Styles.layout.breakpoints.md}>
        <Navigation
          prefix={<MyriadLogo />}
          suffix={
            isAuthenticated() ? (
              <>
                {areLinksAvailable && (
                  <Link to="/onboarding/welcome" styles={styles.link}>
                    <Text css={styles.navTabLink}>
                      {t('labels.registerMyKit')}
                    </Text>
                  </Link>
                )}
                <View pl={2} row>
                  <ProfileMenu />
                </View>
              </>
            ) : null
          }
        >
          {areLinksAvailable &&
            isAuthenticated() &&
            links.map(({ title, href, ...rest }) => (
              <Link key={title} to={href} styles={styles.link} {...rest}>
                <NavTab active={pathname === href} title={title} />
              </Link>
            ))}
        </Navigation>
      </MediaQuery>
      <MediaQuery maxWidth={Styles.layout.breakpoints.md}>
        <Navigation
          prefix={<MyriadLogo />}
          suffix={
            isAuthenticated() ? (
              <ProfileMenu>
                {areLinksAvailable &&
                  links.map(({ title, href, ...rest }) => (
                    <MenuItem
                      key={title}
                      onClick={() => handleLinkClick(href)}
                      disableGutters
                    >
                      {typeof href === 'string' ? (
                        <Link to={href} styles={styles.menuLink} {...rest}>
                          <Text>{title}</Text>
                        </Link>
                      ) : (
                        <A
                          to={href.pathname}
                          styles={styles.menuLink}
                          {...rest}
                        >
                          <Text>{title}</Text>
                        </A>
                      )}
                    </MenuItem>
                  ))}
              </ProfileMenu>
            ) : null
          }
        />
      </MediaQuery>
    </View>
  );
};

export { AppHeader };
export default React.memo(AppHeader);
