import clinical_report from '../constants/clinical_report_response';
import {
  ClinicalReport_getClinicalReport_ReportSuccess,
  ClinicalReport_getClinicalReport_ReportNotReleased,
} from '../features/results/__generated__/ClinicalReport';
import {
  GetMyRiskOrders,
  GetMyRiskOrders_orders_OrdersListSuccess,
} from '../features/results/__generated__/GetMyRiskOrders';
import { ResultsOverview_resultsOverview_ResultsOverviewSuccess } from '../features/results/__generated__/ResultsOverview';
import { ReportsID_getReportIds_GetReportIdsSuccess } from '../features/results/__generated__/ReportsID';
import { GetConsults_consults_ConsultsListSuccess_consultList } from '../features/consults/__generated__/GetConsults';
import { ConsultStatuses } from '../constants/consults';
import { ResultSeverity, TestStatus } from '../types/__generated__/globalTypes';

interface Indexed {
  [key: string]: any;
}

export const orderEstimates = [
  {
    id: '1',
    name: 'Foresight Carrier Screen',
    orderType: 'FORESIGHT',
    estimate: {
      id: '1',
      date: '04/23/2012',
      insurance: 'Cigna',
      state: 'in_progress',
      error: null,
    },
    patients: [
      {
        firstName: 'Julianna',
        lastName: 'Ln',
      },
      {
        firstName: 'First',
        lastName: 'Last',
      },
    ],
    invoice: {
      id: '1',
      number: '7123456789',
      status: 'Invoice ready',
      serviceDate: '2021-08-03T12:20:22.027071',
      paidAt: null,
      paid: null,
      amount: '123.45',
      dueBy: '2021-12-29T12:20:22.027071',
      downloadInvoiceLink: 'https://test',
    },
  },
  {
    id: '2',
    name: 'Second order',
    orderType: 'MY_RISK',
    estimate: {
      id: '2',
      date: '04/23/2012',
      insurance: 'Self-pay',
      state: 'error',
      error: {
        link: 'https://test',
        title: 'We are unable to generate a cost estimate',
        description:
          'A Myriad representative will call you with more information soon. If you hav any questions, please call us at 1-800-469-7423.',
      },
    },
    patients: [
      {
        firstName: 'Julianna',
        lastName: 'Ln',
      },
      {
        firstName: 'First',
        lastName: 'Last',
      },
    ],
    invoice: {
      id: '2',
      number: '7123456789',
      status: 'Paid in full',
      serviceDate: '2021-08-03T12:20:22.027071',
      amount: null,
      dueBy: null,
      paidAt: '2021-08-03T12:20:22.027071',
      paid: '123.45',
      downloadInvoiceLink: null,
    },
  },
  {
    id: '3',
    name: 'MyRisk Hereditary Cancer Test',
    orderType: 'MY_RISK',
    estimate: {
      id: '1',
      date: '04/23/2012',
      insurance: 'Self-pay',
      state: 'done',
      error: null,
    },
    patients: [
      {
        firstName: 'Julianna',
        lastName: 'Ln',
      },
      {
        firstName: 'First',
        lastName: 'Last',
      },
    ],
    invoice: {
      id: '3',
      number: '7123456789',
      status: 'Cancelled',
      serviceDate: '2021-08-03T12:20:22.027071',
      amount: null,
      dueBy: null,
      paidAt: null,
      paid: null,
      downloadInvoiceLink: null,
    },
  },
  {
    id: '4',
    name: 'Foresight Carrier Screen',
    orderType: 'FORESIGHT',
    estimate: {
      id: '2',
      date: '04/23/2012',
      insurance: 'Self-pay',
      state: 'done',
      error: null,
    },
    patients: [
      {
        firstName: 'Julianna',
        lastName: 'Ln',
      },
      {
        firstName: 'First',
        lastName: 'Last',
      },
    ],
    invoice: {
      id: '3',
      number: '7123456789',
      status: 'Cancelled',
      serviceDate: '2021-08-03T12:20:22.027071',
      amount: null,
      dueBy: null,
      paidAt: null,
      paid: null,
      downloadInvoiceLink: null,
    },
  },
];

export const estimates: Indexed = {
  1: {
    id: '1',
    date: '04/23/2012',
    barcode: '98548957689',
    insurance: 'Self-pay',
    state: 'error',
    error: {
      link: 'https://test',
      title: 'We are unable to generate a cost estimate',
      description:
        'A Myriad representative will call you with more information soon. If you hav any questions, please call us at 1-800-469-7423.',
    },
    title: '',
    description: '',
    type: 'INN',
    paymentType: null,
    covered: true,
    isPaid: false,
    tat: 'TAT',
    reasonLink: '',
    insurancePrice: 350,
    guaranteedPrice: 750,
    payer: 'Payer',
    nextStepsDetailsLink: 'http://test',
    financialAssistanceProgramLink: 'http://test',
    paymentPlansLink: 'http://test',
    downloadAgreementLink: 'http://test',
    plans: [
      { month: 24, price: 31 },
      { month: 12, price: 62 },
      { month: 6, price: 125 },
    ],
  },
  2: {
    id: '2',
    date: '04/23/2012',
    barcode: '98548957689',
    insurance: 'Self-pay',
    state: 'error',
    error: {
      link: 'https://test',
      title: 'We are unable to generate a cost estimate',
      description:
        'A Myriad representative will call you with more information soon. If you hav any questions, please call us at 1-800-469-7423.',
    },
    title: '',
    description: '',
    type: 'OON',
    paymentType: null,
    covered: false,
    isPaid: true,
    tat: 'TAT',
    reasonLink: '',
    insurancePrice: 350,
    guaranteedPrice: 750,
    payer: 'Payer',
    nextStepsDetailsLink: 'http://test',
    financialAssistanceProgramLink: 'http://test',
    paymentPlansLink: 'http://test',
    downloadAgreementLink: 'http://test',
    plans: [
      { month: 24, price: 31 },
      { month: 12, price: 62 },
      { month: 6, price: 125 },
    ],
  },
};

export const orderResults = {
  id: '1',
  name: 'Prequel Prenatal Screen',
  orderType: 'PREQUEL',
};

export const results = [
  {
    id: '1',
    severity: 'RED',
    status: 'Positive',
    statusDescription: 'High reproductive risk',
    title: 'Cystic Fibrosis',
    subtitle:
      'Christine and Cory are both carriers. The risk of your child being affected is 1 in 4.',
    description:
      'Cystic Fibrosis (CF) affects many different organs in the body, including the lungs, pancreas, and liver, lining them with an abnormally thick, sticky mucus. The disease causes chronic breathing problems and lung infections. While treatments have improved over the years, the average life expectancy for people with CF who live to adulthood is 35 years. Read more about Cystic Fibrosis',
  },
  {
    id: '2',
    severity: 'YELLOW',
    status: 'Positive',
    statusDescription: 'Carrier',
    title: 'Cystic Fibrosis',
    subtitle:
      'Christine is a carrier but Cory is not a carrier. The risk of your child being affected is 1 in 17,000.',
    description:
      'Cystic Fibrosis (CF) affects many different organs in the body, including the lungs, pancreas, and liver, lining them with an abnormally thick, sticky mucus. The disease causes chronic breathing problems and lung infections. While treatments have improved over the years, the average life expectancy for people with CF who live to adulthood is 35 years. Read more about Cystic Fibrosis',
  },
  {
    id: '3',
    severity: 'GREEN',
    status: 'Negative',
    statusDescription: 'Carrier',
    title: 'Cystic Fibrosis',
    subtitle: 'No chromosome abnormalities were detected.',
    description:
      'This means there is a low risk that your baby has Down syndrome, Turner syndrome, or other chromosome changes that can be identified with this screening test.',
  },
];

export const conditionsTested = [
  {
    id: '1',
    severity: 'RED',
    condition: 'Alkaptonuria',
    affectedChildRisk: '1 in 4',
    notes: '',
  },
  {
    id: '2',
    severity: 'YELLOW',
    condition: 'Alpha-1 Antitrypsin Deficiency',
    affectedChildRisk: '1 in 2',
    notes: '',
  },
  {
    id: '3',
    severity: 'NEUTRAL',
    condition: 'Alpha-mannosidosis',
    affectedChildRisk: '< 1 in 1,000,000',
    notes: 'John not tested',
  },
  {
    id: '4',
    severity: 'NEUTRAL',
    condition: 'Alpha-sarcoglycanopathy',
    affectedChildRisk: '< 1 in 1,000,000',
    notes: 'John not tested',
  },
  {
    id: '5',
    severity: 'NEUTRAL',
    condition: 'Andermann Syndrome',
    affectedChildRisk: '< 1 in 1,000,000',
    notes: 'John not tested',
  },
];

const trackerSteps = [
  {
    id: '0',
    status: 'DONE',
    label: 'Order received',
    modified: '2021-06-23T12:19:43.511Z',
    actions: [],
  },
  {
    id: '1',
    status: 'DONE',
    label: 'Preparing your testing kit',
    modified: '2021-06-23T12:19:43.511Z',
    actions: [],
  },
  {
    id: '2',
    status: 'DONE',
    label: 'Kit shipped',
    modified: '2021-06-23T12:19:43.511Z',
    actions: [],
  },
  {
    id: '3',
    status: 'IN_PROGRESS',
    label: 'Return sample to Myriad',
    modified: '2021-06-23T12:19:43.511Z',
    actions: [],
  },
  {
    id: '4',
    status: 'PLANNED',
    label: 'Sample in transit',
    modified: '2021-06-23T12:19:43.511Z',
    actions: [],
  },
];

const samples = {
  patient: {
    id: '123456789',
    orderType: 'Foresight Carrier Screen',
    patientFirstName: 'Juliana',
    patientLastName: 'Linder',
    receivedDate: '2021-08-03T12:20:22.027071',
    releasedDate: '2021-08-03T12:20:22.027071',
    panelLabel: 'Custom panel 1097 | fundamental panel',
    diseasesTested: '102',
  },
  partner: {
    id: '987654321',
    orderType: 'Foresight Carrier Screen',
    patientFirstName: 'Julian',
    patientLastName: 'Linder',
    receivedDate: '2021-08-03T12:20:22.027071',
    releasedDate: '2021-09-03T12:20:22.027071',
    panelLabel: 'Prefix panel | ABCC*, CFTR',
    diseasesTested: '2',
  },
};

export const testsInfo = [
  {
    id: '1',
    hasReleasedResults: false,
    releaseDate: '2021-07-03T12:20:22.027071',
    orderType: 'Foresight Carrier Screen',
    trackerSteps,
    samples,
    doctor: {
      firstName: 'Jack',
      lastName: 'Retches',
      fax: '415-123-4567',
      phone: '415-123-4567',
      streetAddress: 'street address',
      clinicName: 'clinic name',
      state: 'CA',
      city: 'San Francisco',
    },
    patients: {
      preferredPhone: '415-123-4567',
      screeningFor: 'my_and_my_partner',
      patient: {
        id: '1',
        lastName: 'Born',
        firstName: 'Jason',
        email: '',
        dob: '1995-08-03T12:20:22.027071',
        ethnicity: 'Finnish',
        pregnant: '',
        sex: 'Male',
        testingReason: 'Foresight Carrier Screen',
        dueDate: null,
        additionalInfo: '123',
      },
      partner: {
        id: '2',
        lastName: 'Born',
        firstName: 'Mary',
        email: '',
        dob: '1987-08-03T12:20:22.027071',
        ethnicity: 'Finnish',
        pregnant: 'Not pregnant',
        sex: 'Female',
        testingReason: 'Foresight Carrier Screen',
        dueDate: null,
        additionalInfo: '123',
      },
    },
    shipping: {
      firstName: 'Julian',
      lastName: 'Linder',
      address: '5 street',
      other: '',
      city: 'Los Angeles',
      state: 'CA',
      zip: '12345',
    },
    billing: {
      preferredPaymentMethod: 'INSURANCE',
      insurance: {
        policyHolders: [
          {
            companyName: 'Blue Shield California',
            patientId: '1',
            memberId: '123',
            groupNumber: '1',
            employer: 'Goverment',
            insurancePhoneNumber: '123',
            isPrimary: true,
            address: 'Baker street',
            other: '123',
            city: 'London',
            state: 'UTAH',
            zip: '123',
          },
          {
            companyName: 'Blue Shield California',
            patientId: '1',
            memberId: '1234',
            groupNumber: '1',
            employer: 'Goverment',
            insurancePhoneNumber: '123',
            isPrimary: false,
            address: 'Baker street',
            other: '123',
            city: 'London',
            state: 'UTAH',
            zip: '12345',
          },
          {
            companyName: 'Blue Shield California',
            patientId: '2',
            memberId: '1234',
            groupNumber: '1',
            employer: 'Goverment',
            insurancePhoneNumber: '123',
            isPrimary: true,
            address: 'Baker street',
            other: '123',
            city: 'London',
            state: 'UTAH',
            zip: '12345',
          },
          {
            companyName: 'Blue Shield California',
            patientId: '2',
            memberId: '1234',
            groupNumber: '1',
            employer: 'Goverment',
            insurancePhoneNumber: '123',
            isPrimary: false,
            address: 'Baker street',
            other: '123',
            city: 'London',
            state: 'UTAH',
            zip: '12345',
          },
        ],
        accountHolder: 'test',
        isMarried: false,
        isPlaneTheSame: false,
      },
      card: {
        type: 'VISA',
        cardholder: 'test',
        number: '1234123412341234',
        securityCode: '111',
        expirationDate: '02/24',
        zip: '12345',
      },
      availableCards: {
        id: '1',
        type: 'VISA',
        expirationDate: '12/2045',
        number: '1234',
        selected: true,
      },
      estimatedPrice: {
        name: 'test',
        price: 123,
      },
    },
    history: [
      {
        id: '1',
        resultsFor: {
          id: '1',
          lastName: 'Born',
          firstName: 'Jason',
          email: '',
          dob: '06/09/2021',
          ethnicity: 'Finnish',
          pregnant: '',
          sex: 'Male',
          testingReason: 'Consanguinity',
          dueDate: null,
          additionalInfo: '123',
        },
        type: 'Final',
        releasedOn: '2021-07-03T12:20:22.027071',
        link: 'https://pp20-patient-portal-apis.sandbox.counsyl.com/my/api/v2/consults/HJCEALPM34ZH/report/',
      },
      {
        id: '2',
        resultsFor: {
          id: '1',
          lastName: 'Born',
          firstName: 'Jason',
          email: '',
          dob: '06/09/2021',
          ethnicity: 'Finnish',
          pregnant: '',
          sex: 'Male',
          testingReason: 'Consanguinity',
          dueDate: null,
          additionalInfo: '123',
        },
        type: 'Amended',
        releasedOn: '2021-07-03T12:20:22.027071',
        link: 'https://pp20-patient-portal-apis.sandbox.counsyl.com/my/api/v2/consults/HJCEALPM34ZH/report/',
      },
      {
        id: '3',
        resultsFor: {
          id: '1',
          lastName: 'Born',
          firstName: 'Jason',
          email: '',
          dob: '06/09/2021',
          ethnicity: 'Finnish',
          pregnant: '',
          sex: 'Male',
          testingReason: 'Consanguinity',
          dueDate: null,
          additionalInfo: '123',
        },
        type: 'Corrected',
        releasedOn: '2021-07-03T12:20:22.027071',
        link: 'https://pp20-patient-portal-apis.sandbox.counsyl.com/my/api/v2/consults/HJCEALPM34ZH/report/',
      },
      {
        id: '4',
        resultsFor: {
          id: '1',
          lastName: 'Born',
          firstName: 'Jason',
          email: '',
          dob: '06/09/2021',
          ethnicity: 'Finnish',
          pregnant: '',
          sex: 'Male',
          testingReason: 'Consanguinity',
          dueDate: null,
          additionalInfo: '123',
        },
        type: 'Preliminary',
        releasedOn: '2021-07-03T12:20:22.027071',
        link: 'https://pp20-patient-portal-apis.sandbox.counsyl.com/my/api/v2/consults/HJCEALPM34ZH/report/',
      },
    ],
  },
  {
    id: '2',
    hasReleasedResults: true,
    releaseDate: '2021-08-03T12:20:22.027071',
    orderType: 'Foresight Carrier Screen',
    trackerSteps: [],
    samples,
    doctor: {
      firstName: 'Jack',
      lastName: 'Retches',
      fax: '415-123-4567',
      phone: '415-123-4567',
      streetAddress: 'street address',
      clinicName: 'clinic name',
      state: 'CA',
      city: 'San Francisco',
    },
    patients: {
      preferredPhone: '415-123-4567',
      screeningFor: 'my_and_my_partner',
      patient: {
        id: '1',
        lastName: 'Born',
        firstName: 'Jason',
        email: '',
        dob: '2000-08-03T12:20:22.027071',
        ethnicity: 'Finnish',
        pregnant: '',
        sex: 'Male',
        testingReason: 'Consanguinity',
        dueDate: null,
        additionalInfo: '123',
      },
      partner: {
        id: '2',
        lastName: 'Born',
        firstName: 'Mary',
        email: '',
        dob: '2000-08-03T12:20:22.027071',
        ethnicity: 'Finnish',
        pregnant: 'Not pregnant',
        sex: 'Female',
        testingReason: 'Consanguinity',
        dueDate: null,
        additionalInfo: '123',
      },
    },
    shipping: {
      firstName: 'Julian',
      lastName: 'Linder',
      address: '5 street',
      other: '',
      city: 'Los Angeles',
      state: 'CA',
      zip: '12345',
    },
    billing: {
      preferredPaymentMethod: 'CARD',
      insurance: {
        policyHolders: [
          {
            companyName: 'MI6',
            patientId: '',
            memberId: '123',
            groupNumber: '1',
            employer: 'Goverment',
            insurancePhoneNumber: '123',
            isPrimary: true,
            address: 'Baker street',
            other: '123',
            city: 'London',
            state: 'UTAH',
            zip: '123',
          },
        ],
        accountHolder: 'test',
        isMarried: false,
        isPlaneTheSame: false,
      },
      card: {
        type: 'VISA',
        cardholder: 'test',
        number: '1234123412341234',
        securityCode: '111',
        expirationDate: '02/24',
        zip: '12345',
      },
      availableCards: {
        id: '1',
        type: 'VISA',
        expirationDate: '12/2045',
        number: '1234',
        selected: true,
      },
      estimatedPrice: {
        name: 'test',
        price: 123,
      },
    },
    history: [
      {
        id: '5',
        resultsFor: {
          id: '1',
          lastName: 'Born',
          firstName: 'Jason',
          email: '',
          dob: '06/09/2021',
          ethnicity: 'Finnish',
          pregnant: '',
          sex: 'Male',
          testingReason: 'Consanguinity',
          dueDate: null,
          additionalInfo: '123',
        },
        type: 'Final',
        releasedOn: '2021-07-03T12:20:22.027071',
        link: 'https://pp20-patient-portal-apis.sandbox.counsyl.com/my/api/v2/consults/HJCEALPM34ZH/report/',
      },
      {
        id: '6',
        resultsFor: {
          id: '1',
          lastName: 'Born',
          firstName: 'Jason',
          email: '',
          dob: '06/09/2021',
          ethnicity: 'Finnish',
          pregnant: '',
          sex: 'Male',
          testingReason: 'Consanguinity',
          dueDate: null,
          additionalInfo: '123',
        },
        type: 'Amended',
        releasedOn: '2021-07-03T12:20:22.027071',
        link: 'https://pp20-patient-portal-apis.sandbox.counsyl.com/my/api/v2/consults/HJCEALPM34ZH/report/',
      },
      {
        id: '7',
        resultsFor: {
          id: '1',
          lastName: 'Born',
          firstName: 'Jason',
          email: '',
          dob: '06/09/2021',
          ethnicity: 'Finnish',
          pregnant: '',
          sex: 'Male',
          testingReason: 'Consanguinity',
          dueDate: null,
          additionalInfo: '123',
        },
        type: 'Corrected',
        releasedOn: '2021-07-03T12:20:22.027071',
        link: 'https://pp20-patient-portal-apis.sandbox.counsyl.com/my/api/v2/consults/HJCEALPM34ZH/report/',
      },
      {
        id: '8',
        resultsFor: {
          id: '1',
          lastName: 'Born',
          firstName: 'Jason',
          email: '',
          dob: '06/09/2021',
          ethnicity: 'Finnish',
          pregnant: '',
          sex: 'Male',
          testingReason: 'Consanguinity',
          dueDate: null,
          additionalInfo: '123',
        },
        type: 'Preliminary',
        releasedOn: '2021-07-03T12:20:22.027071',
        link: 'https://pp20-patient-portal-apis.sandbox.counsyl.com/my/api/v2/consults/HJCEALPM34ZH/report/',
      },
    ],
  },
];

export const myRiskResults = {
  orders: {
    __typename: 'OrdersListSuccess',
    ordersList: [
      {
        __typename: 'OrderItemType',
        externalId: 'Q4ZYGAFGPA9V',
        accessionId: '12345678-BLD',
        createDate: '2022-08-02T15:57:46',
        hasReleasedResults: true,
        product: 'myRisk® Test',
        patientFirstName: 'Jeff',
        patientLastName: 'Smith',
      },
    ],
  } as GetMyRiskOrders_orders_OrdersListSuccess,
};

export const resultsOverview = ({
  severity = ResultSeverity.NEGATIVE,
  status = TestStatus.REPORTED,
  splitResult = false,
  testNameOne = 'BRACAnalysis',
  testNameTwo = 'MyRisk',
} = {}) => {
  const resultsOverviewResponse: ResultsOverview_resultsOverview_ResultsOverviewSuccess =
    {
      __typename: 'ResultsOverviewSuccess',
      overallSeverity: severity,
      resultsReleased: '2022-11-17',
      patient: {
        __typename: 'PatientType',
        firstName: 'Mindy',
        lastName: 'Berry',
        email: 'myrisk_one_result_one_cancelled_1154@email.com',
        phone: '(734) 386-1972',
        accessionId: '12345678-BLD',
      },
      product: {
        __typename: 'ResultProduct',
        id: 5,
        slug: 'my-risk',
        name: 'myRisk Test',
      },
      tests: [
        {
          __typename: 'TestType',
          testName: testNameOne,
          testSeverity: severity,
          reportId: 'sdgfsdfgsdfg',
          resultsReleasedToPatient: '2022-11-19',
          testStatus: status,
          resultsViewedByPatient: false,
        },
      ],
    };

  if (splitResult) {
    resultsOverviewResponse!.tests!.push({
      __typename: 'TestType',
      testName: testNameTwo,
      testSeverity: ResultSeverity.ELEVATED_RISK,
      reportId: 'sdgfsdfgsdfg',
      resultsReleasedToPatient: '2022-11-16',
      testStatus: TestStatus.REPORTED,
      resultsViewedByPatient: false,
    });
  }

  return {
    resultsOverview: resultsOverviewResponse,
  };
};

export const emptyMyRiskResults: GetMyRiskOrders = {
  orders: {
    ordersList: [],
    __typename: 'OrdersListSuccess',
  },
};

export const clinicalReportIDs: ReportsID_getReportIds_GetReportIdsSuccess = {
  __typename: 'GetReportIdsSuccess',
  reportIds: ['ba73361d-2ce8-4804-85f3-e12896464921'],
};

export const clinicalReport: ClinicalReport_getClinicalReport_ReportSuccess = {
  __typename: 'ReportSuccess',
  contentType: null,
  filename: 'BRACAnalysisCDx-10003648-BLD.pdf',
  report: clinical_report,
};

export const clinicalReportNotReleased: ClinicalReport_getClinicalReport_ReportNotReleased =
  {
    __typename: 'ReportNotReleased',
  };

export const pendingConsult: GetConsults_consults_ConsultsListSuccess_consultList =
  {
    id: 'YVHC3R2G4E3M',
    onDemand: false,
    created: '2022-11-09T22:30:23.819859-08:00',
    phone: '(325) 345-6431',
    status: ConsultStatuses.PENDING,
    topic: 'GENETIC_COUNSELING',
    bookedSlot: '2022-11-11T13:30:00-08:00',
    email: 'dummy122@dummy.com',
    queueDescription: null,
    counselor: {
      bio: 'Kenny Wong hails from the French Pyrenees, where he grew up helping his\n        parents operate their boutique yak shaving business, and honing his\n        innate talent for genetic counseling, which was apparent from a very\n        young age (his first words were "guanine-cytosine"). Kenny is an\n        olympic croquet player, who enjoys panoramic mountain vistas, cave-aged\n        gruyere, and the movie The Sound of Music. He lives in San Francisco,\n        with a flock of very tiny sheep.',
      firstName: 'Kenny',
      id: null,
      lastName: 'Wong',
      photo:
        '/site_media/uploads/public/img/healthcareprofile/kenny-wong-thumb.jpg',
      __typename: 'CounselorType',
    },
    __typename: 'ConsultListItemType',
  };

export const completedConsult: GetConsults_consults_ConsultsListSuccess_consultList =
  {
    id: '6D4RN44LW6F2',
    onDemand: false,
    created: '2022-10-02T11:48:02.577205-07:00',
    phone: '(325) 345-6435',
    status: ConsultStatuses.COMPLETED,
    topic: 'MYRISK_COUNSELING',
    bookedSlot: '2022-10-03T12:00:00-07:00',
    email: 'dummy3@dummy.com',
    queueDescription: null,
    counselor: {
      bio: 'Kenny Wong hails from the French Pyrenees, where he grew up helping his\n        parents operate their boutique yak shaving business, and honing his\n        innate talent for genetic counseling, which was apparent from a very\n        young age (his first words were "guanine-cytosine"). Kenny is an\n        olympic croquet player, who enjoys panoramic mountain vistas, cave-aged\n        gruyere, and the movie The Sound of Music. He lives in San Francisco,\n        with a flock of very tiny sheep.',
      firstName: 'Kenny',
      id: null,
      lastName: 'Wong',
      photo: null,
      __typename: 'CounselorType',
    },
    __typename: 'ConsultListItemType',
  };
