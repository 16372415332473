import { NavigationTab, Text } from 'primase';

// Styles
import { styles } from './styles';

interface INavTab {
  active: boolean;
  title: string | React.ReactNode;
}

const NavTab = ({ active, title }: INavTab) => (
  <NavigationTab
    css={styles.navTabWrapper}
    active={active}
    key={`tab-${title}`}
  >
    {typeof title === 'string' ? (
      <Text css={styles.navTabText} type="small">
        {title}
      </Text>
    ) : (
      title
    )}
  </NavigationTab>
);

export default NavTab;
