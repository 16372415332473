import { InMemoryCache } from '@apollo/client';
import { userInfoVar } from './globals';
import { myRiskResults } from './mocks';

/**
 * Apollo client cache config
 */
export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        userInfo: {
          read() {
            return userInfoVar();
          },
        },
        // Mocked responses
        isIntroPassed: {
          read() {
            return localStorage.getItem('myriad:introPassed');
          },
          merge(_: unknown, incoming: boolean) {
            localStorage.setItem('myriad:introPassed', String(incoming));

            return incoming;
          },
        },
        isEstimatesInfoClosed: {
          read() {
            return localStorage.getItem('myriad:isEstimatesInfoClosed');
          },
          merge(_: unknown, incoming: boolean) {
            localStorage.setItem(
              'myriad:isEstimatesInfoClosed',
              String(incoming),
            );

            return incoming;
          },
        },
        pendingOrders: {
          read() {
            return [
              {
                id: '5',
                name: 'Pending authorization',
                orderType: 'FORESIGHT',
                account: {
                  email: 'adamsjennifer@yahoo.com',
                  firstName: 'Jason',
                  lastName: 'Born',
                  poll: {
                    portal: null,
                  },
                },
                patients: [
                  {
                    firstName: 'Jason',
                    lastName: 'Born',
                  },
                ],
                description:
                  'We are reaching out to your doctor to complete the authorization needed to approve the order.',
                modified: '2021-06-23T12:29:43.511Z',
                steps: [],
              },
              {
                id: '6',
                name: 'test',
                orderType: 'FORESIGHT',
                account: {
                  email: 'adamsjennifer@yahoo.com',
                  firstName: 'Jason',
                  lastName: 'Born',
                },
                patients: [
                  {
                    firstName: 'Jason',
                    lastName: 'Born',
                  },
                ],
                description:
                  'We are reaching out to your doctor to complete the authorization needed to approve the order.',
                modified: '2021-06-23T12:29:43.511Z',
                resultStatus: 'RESULTS_MANAGED',
                steps: [
                  {
                    id: '1',
                    status: 'done',
                    label: '',
                    modified: null,
                    actions: [],
                  },
                ],
              },
              {
                id: '7',
                name: 'test',
                orderType: 'FORESIGHT',
                account: {
                  email: 'adamsjennifer@yahoo.com',
                  firstName: 'Jason',
                  lastName: 'Born',
                },
                patients: [
                  {
                    firstName: 'Jason',
                    lastName: 'Born',
                  },
                ],
                description:
                  'We are reaching out to your doctor to complete the authorization needed to approve the order.',
                modified: '2021-06-23T12:29:43.511Z',
                resultStatus: 'RESULTS_READY',
                steps: [
                  {
                    id: '1',
                    status: 'done',
                    label: '',
                    modified: null,
                    actions: [],
                  },
                ],
              },
              {
                id: '8',
                name: 'test',
                orderType: 'FORESIGHT',
                account: {
                  email: 'adamsjennifer@yahoo.com',
                  firstName: 'Jason',
                  lastName: 'Born',
                },
                patients: [
                  {
                    firstName: 'Jason',
                    lastName: 'Born',
                  },
                ],
                description:
                  'We are reaching out to your doctor to complete the authorization needed to approve the order.',
                modified: '2021-06-23T12:29:43.511Z',
                resultStatus: 'MANAGEMENT_OFF',
                steps: [
                  {
                    id: '1',
                    status: 'done',
                    label: '',
                    modified: null,
                    actions: [],
                  },
                ],
              },
            ];
          },
        },
        orderEstimates: {
          read() {
            return [];
          },
        },
        estimate: {
          read() {
            return {};
          },
        },
        resultsDetails: {
          read() {
            return null;
          },
        },
        resultsInfo: {
          read() {
            return {
              orderId: 2,
              isShownEarlier: Boolean(
                localStorage.getItem(`myriad:isShownEarlier-2`), // do not set isShownEarlier to localStorage, get it from back end
              ),
            };
          },
          merge(
            _: unknown,
            incoming: {
              orderId: string;
              isShownEarlier: boolean;
            },
          ) {
            localStorage.setItem(
              `myriad:isShownEarlier-${incoming.orderId}`,
              String(incoming.isShownEarlier),
            );

            return incoming;
          },
        },
        tests_info: {
          read() {
            return [];
          },
        },
        myrisk_results: {
          read() {
            return myRiskResults;
          },
        },
      },
    },
  },
});
