import clsx from 'clsx';

import styles from './styles.module.scss';
import FadeInWrapper from '../fade-in-wrapper';

interface ILoaderProps {
  fullScreen?: boolean;
  show?: boolean;
}

const Loader = ({ fullScreen, show = true }: ILoaderProps) => (
  <FadeInWrapper show={show}>
    <div
      className={clsx(styles.container, {
        [styles.fullScreen]: fullScreen,
      })}
      data-testid="loader"
    >
      <div className={styles.ldsGrid}>
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  </FadeInWrapper>
);

Loader.defaultProps = {
  fullScreen: false,
};

export default Loader;
