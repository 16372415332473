import { Route, Switch } from 'react-router-dom';
import { ThemeProvider } from 'primase';
import loadable from '@loadable/component';

// Components
import PrivateRoute from '../../components/private-route';
import AppHeader from '../../components/app-header';
import AppFooter from '../../components/app-footer';
import PageLayout from '../../components/layout';
import Loader from '../../components/loader';

// Constants
import { ROLES } from '../../constants/auth';
import { PAGES } from '../../constants/pages';

// Styles
import { theme } from './styles';
import BaseRedirect from '../../components/base-redirect';

// Lazy loaded components
const Intro = loadable(() => import('../intro'));
const ResultsDetailsFlow = loadable(
  () => import('../results/components/results-details-flow'),
);
const Account = loadable(() => import('../account/account'));
const My = loadable(() => import('../my'));
const NewConsult = loadable(() => import('../consults/new-consult'));
const Onboarding = loadable(() => import('../onboarding'));
const PageNotFound = loadable(() => import('../page-not-found'));
const ImpersonationLandingPage = loadable(
  () =>
    import(
      '../account/components/impersonation/impersonation-landing-page/impersonation-landing-page'
    ),
);

const ScrollTop = () => {
  window.scrollTo(0, 0);

  return null;
};

const Root = () => (
  <ThemeProvider theme={theme}>
    <Route path={PAGES.BASE} component={ScrollTop} />
    <Switch>
      <Route exact path={PAGES.BASE}>
        <BaseRedirect to={`${PAGES.MY}${PAGES.TESTS}`} />
      </Route>
      <Route path={PAGES.ACCOUNT}>
        <Account fallback={<Loader fullScreen />} />
      </Route>
      <PrivateRoute
        exact
        path={`${PAGES.INTRO}`}
        roles={[ROLES.ADMIN, ROLES.USER]}
      >
        <Intro fallback={<Loader fullScreen />} />
      </PrivateRoute>
      <PrivateRoute
        path={`${PAGES.CONSULTS}`}
        roles={[ROLES.ADMIN, ROLES.USER]}
      >
        <NewConsult fallback={<Loader fullScreen />} />
      </PrivateRoute>
      <PrivateRoute
        path={`${PAGES.ORDERS}/:orderId${PAGES.RESULTS}`}
        roles={[ROLES.ADMIN, ROLES.USER]}
      >
        <ResultsDetailsFlow fallback={<Loader fullScreen />} />
      </PrivateRoute>
      <Route path={`${PAGES.ONBOARDING}`}>
        <Onboarding fallback={<Loader fullScreen />} />
      </Route>
      <Route path={`${PAGES.MY}${PAGES.IMPERSONATE}`}>
        <ImpersonationLandingPage />
      </Route>
      <PageLayout header={<AppHeader />} footer={<AppFooter />}>
        <Switch>
          <PrivateRoute path={`${PAGES.MY}`} roles={[ROLES.ADMIN, ROLES.USER]}>
            <My fallback={<Loader fullScreen />} />
          </PrivateRoute>
          <Route>
            <PageNotFound fallback={<Loader fullScreen />} />
          </Route>
        </Switch>
      </PageLayout>
    </Switch>
  </ThemeProvider>
);

export default Root;
