import i18n from '../i18n';
import { MYRISK_NAME } from './results';

export const INTERPRETER_LANGUAGES = [
  'Acholi',
  'Afar',
  'Afrikaans',
  'Akan',
  'Akateko',
  'Albanian',
  'Amharic',
  'Anuak',
  'Apache',
  'Arabic',
  'Armenian',
  'Assyrian',
  'Azerbaijani',
  'Bahasa',
  'Bahdini',
  'Bajuni',
  'Bambara',
  'Bantu',
  'Barese',
  'Basque',
  'Bassa',
  'Belorussian',
  'Bemba',
  'Benaadir',
  'Bengali',
  'Berber',
  'Bosnian',
  'Bravanese',
  'Bulgarian',
  'Burmese',
  'Cantonese',
  'Catalan',
  'Cebuano',
  'Chaldean',
  'Chamorro',
  'Chaochow',
  'Chin Falam',
  'Chin Hakha',
  'Chin Mara',
  'Chin Matu',
  'Chin Senthang',
  'Chin Tedim',
  'Chipewyan',
  'Chuukese',
  'Cree',
  'Croatian',
  'Czech',
  'Danish',
  'Dari',
  'Dewoin',
  'Dinka',
  'Duala',
  'Dutch',
  'Dzongkha',
  'Edo',
  'Ekegusii',
  'Estonian',
  'Ewe',
  'Farsi',
  'Fijian',
  'Fijian Hindi',
  'Finnish',
  'Flemish',
  'French',
  'French Canadian',
  'Fukienese',
  'Fulani',
  'Fuzhou',
  'Ga',
  'Gaddang',
  'Gaelic-Irish',
  'Gaelic-Scottish',
  'Garre',
  'Gen',
  'Georgian',
  'German',
  'German Penn. Dutch',
  'Gheg',
  'Gokana',
  'Greek',
  'Gujarati',
  'Gulay',
  'Gurani',
  'Haitian Creole',
  'Hakka-China',
  'Hakka-Taiwan',
  'Hassaniyya',
  'Hausa',
  'Hawaiian',
  'Hebrew',
  'Hiligaynon',
  'Hindi',
  'Hindko',
  'Hmong',
  'Hunanese',
  'Hungarian',
  'Icelandic',
  'Igbo',
  'Ilocano',
  'Indonesian',
  'Inuktitut',
  'Italian',
  'Jakartanese',
  'Jamaican Patois',
  'Japanese',
  'Jarai',
  'Javanese',
  'Jingpho',
  'Jinyu',
  'Juba Arabic',
  'Jula',
  'Kaba',
  'Kamba',
  'Kam Muang',
  'Kanjobal',
  'Kannada',
  'Karen',
  'Kashmiri',
  'Kayah',
  'Kazakh',
  'Kham',
  'Khana',
  'Khmer',
  'K’iché',
  'Kikuyu',
  'Kimiiru',
  'Kinyarwanda',
  'Koho',
  'Korean',
  'Krahn',
  'Krio',
  'Kunama',
  'Kurmanji',
  'Kyrgyz',
  'Laotian',
  'Latvian',
  'Liberian Pidgin English',
  'Lingala',
  'Lithuanian',
  'Luba-Kasai',
  'Luganda',
  'Luo',
  'Maay',
  'Macedonian',
  'Malay',
  'Malayalam',
  'Maltese',
  'Mam',
  'Mandarin',
  'Mandinka',
  'Maninka',
  'Manobo',
  'Marathi',
  'Marka',
  'Marshallese',
  'Masalit',
  'Mbay',
  'Mien',
  'Mirpuri',
  'Mixteco',
  'Mizo',
  'Mnong',
  'Mongolian',
  'Moroccan Arabic',
  'Mortlockese',
  'Napoletano',
  'Navajo',
  'Nepali',
  'Ngambay',
  'Nigerian Pidgin',
  'Norwegian',
  'Nuer',
  'Nupe',
  'Nyanja',
  'Nyoro',
  'Ojibway',
  'Oromo',
  'Pampangan',
  'Papiamento',
  'Pashto',
  'Plautdietsch',
  'Pohnpeian',
  'Polish',
  'Portuguese',
  'Portuguese Brazilian',
  'Portuguese Cape Verdean',
  'Pugliese',
  'Pulaar',
  'Punjabi',
  'Putian',
  "Q'eqchi'",
  'Quechua',
  'Quichua',
  'Rade',
  'Rakhine',
  'Rohingya',
  'Romanian',
  'Rundi',
  'Russian',
  'Samoan',
  'Sango',
  'Seraiki',
  'Serbian',
  'Shanghainese',
  'Shona',
  'Sichuan Yi',
  'Sicilian',
  'Sinhala',
  'Slovak',
  'Slovene',
  'Soga',
  'Somali',
  'Soninke',
  'Sorani',
  'Spanish',
  'Sudanese Arabic',
  'Sunda',
  'Susu',
  'Swahili',
  'Swedish',
  'Sylhetti',
  'Tagalog',
  'Taiwanese',
  'Tajik',
  'Tamil',
  'Teluga',
  'Thai',
  'Tibetan',
  'Tigré',
  'Tigrigna',
  'Toishanese',
  'Tongan',
  'Tooro',
  'Trique',
  'Turkish',
  'Turkmen',
  'Tzotzil',
  'Ukranian',
  'Urdu',
  'Uyghur',
  'Uzbek',
  'Vietnamese',
  'Visayan',
  'Welsh',
  'Wodaabe',
  'Wolof',
  'Yemeni Arabic',
  'Yiddish',
  'Yoruba',
  'Yunnanese',
  'Zapoteco',
  'Zarma',
  'Zo',
  'Zyphe',
];

export enum ConsultStatuses {
  COMPLETED = 'COMPLETED',
  PENDING = 'PENDING',
  REVIEW = 'REVIEW',
}

export enum TIME_ZONE {
  Eastern = 'America/New_York',
  Central = 'America/Chicago',
  Mountain = 'America/Denver',
  MountainArizona = 'America/Phoenix',
  Pacific = 'America/Los_Angeles',
  Alaska = 'America/Anchorage',
  Hawaii = 'Pacific/Honolulu',
}

export const CONSULT_TOPIC = {
  Foresight: {
    label: 'Foresight Carrier Screen',
    value: 'GENETIC_COUNSELING',
  } as const,
  Prequel: {
    label: 'Prequel Prenatal Screen',
    value: 'IPS_COUNSELING',
  } as const,
  myRisk: {
    label: 'MyRisk™ Hereditary Cancer Test',
    value: 'MYRISK_COUNSELING',
  } as const,
  DonorRecipient: {
    label: 'I am a recipient patient and I want to review a donor result',
    value: 'DONOR_RECIPIENT_COUNSELING',
  } as const,
  Reliant: {
    label: 'Reliant Cancer Screen',
    value: 'BRCA_COUNSELING',
  } as const,
};

export const PRODUCT_NAMES = {
  MYRISK: i18n.t('results.myRiskTest', { product: MYRISK_NAME }),
};
