/* eslint-disable no-script-url */
import { View, Text, Styles } from 'primase';
import { useTranslation } from 'react-i18next';
import { Helmet, HelmetTags } from 'react-helmet';
import { useEffect, useState } from 'react';

import { A } from '../link';

const styles = {
  wrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    transform: 'translateY(-100%)',
    width: 1032,
    maxWidth: '100%',
    margin: '0 auto',
    padding: `0 ${Styles.spacing.sp2}px`,
    display: 'flex',
    alignItems: 'flex-end',
    pointerEvents: 'none',
  },
  link: {
    marginLeft: 'auto',
    maxWidth: 180,
  },
  button: {
    textAlign: 'center',
    padding: '6px 16px',
    backgroundColor: Styles.color.blue[700],
    color: Styles.color.neutral.white,
    fontSize: 16,
    fontWeight: 900,
    lineHeight: 20,
    borderRadius: '4px 4px 0 0',
    pointerEvents: 'auto !important',
  },
  linkWrapper: {
    [`@media screen and (max-width: ${Styles.layout.Breakpoints.xs}px)`]: {
      margin: '2px auto',
    },
  },
};

const SupportLink = () => {
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const { setScriptLoaded } = useLiveAgent();

  // Initialize chat link after script loads
  const handleStateChange = (_newState: any, addedTags: HelmetTags) => {
    if (addedTags?.scriptTags?.length) {
      const [deploymentScript] = addedTags.scriptTags;

      deploymentScript.onload = () => {
        setScriptLoaded(true);
      };
    }
  };
  const startChat = () => {
    if (window.liveagent) {
      window.liveagent.startChat(window.env.REACT_APP_LIVE_AGENT_CHAT_BTN_ID);
    }
  };

  return (
    <View css={styles.wrapper} data-testid="support-link__wrapper">
      <Helmet onChangeClientState={handleStateChange}>
        <script
          defer
          type="text/javascript"
          src={window.env.REACT_APP_LIVE_AGENT_DEPLOYMENT_JS}
        />
      </Helmet>
      <View inline css={styles.linkWrapper}>
        <A
          to="javascript://Chat"
          onClick={startChat}
          styles={{
            ...styles.link,
            display: window.liveagent !== undefined ? '' : 'none',
          }}
        >
          <View css={styles.button}>
            <Text id="live-agent-online" style={{ display: 'none' }}>
              {t('labels.liveChat')}
            </Text>
            <Text id="live-agent-offline">{t('labels.contactSupport')}</Text>
          </View>
        </A>
      </View>
    </View>
  );
};

function useLiveAgent() {
  const [scriptLoaded, setScriptLoaded] = useState(false);

  useEffect(() => {
    if (window.liveagent) {
      if (!window._laq) {
        window._laq = [];
      }

      if (window._laq.length === 0) {
        window._laq.push(() => {
          window.liveagent.showWhenOnline(
            window.env.REACT_APP_LIVE_AGENT_CHAT_BTN_ID,
            document.getElementById('live-agent-online'),
          );
          window.liveagent.showWhenOffline(
            window.env.REACT_APP_LIVE_AGENT_CHAT_BTN_ID,
            document.getElementById('live-agent-offline'),
          );
        });

        window.liveagent.init(
          window.env.REACT_APP_LIVE_AGENT_ENDPOINT_API,
          window.env.REACT_APP_LIVE_AGENT_DEPLOYMENT_ID,
          window.env.REACT_APP_LIVE_AGENT_ORG_ID,
        );
      }
    }

    return () => {
      window._laq?.pop();
    };
  }, [scriptLoaded]);

  return { scriptLoaded, setScriptLoaded };
}

export default SupportLink;
