import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useApolloClient, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

// Components
import { InvalidEmailPlain } from '../../types/__generated__/globalTypes';
import Loader from '../../components/loader';

// Queries, Helpers, Types
import {
  TAccountContext,
  TLoginQueryVars,
  TLogoutQueryVars,
  TLogoutQueryResult,
  TRegisterQueryVars,
  TChangePasswordQueryVars,
} from './types';
import {
  LOGIN_QUERY,
  REGISTER_QUERY,
  LOGOUT_QUERY,
  REFRESH_MUTATION,
  CHANGE_PASSWORD_MUTATION,
} from './queries';
import { useToasts } from '../../helpers/hooks';
import {
  getTokenRefreshInterval,
  getTokenLocalStorage,
  isTokenValid,
  setToken,
  setTokens,
  useUserInfo,
  isImpersonatingActive,
  setIsImpersonatingActive,
  getUnimpersonateURL,
} from './helpers';

// Constants
import { PAGES } from '../../constants/pages';
import { Login_login_LoginSuccess } from './__generated__/Login';
import { Register_register_RegisterSuccess } from './__generated__/Register';
import { RefreshToken_refreshToken } from './__generated__/RefreshToken';

export const AccountContext = React.createContext<TAccountContext>({
  isAuthenticated: () => false,
  isImpersonating: () => false,
  user: { email: '', firstName: '', lastName: '' },
  login: async () => {},
  logout: async () => {},
  register: async () => {},
  redirectToLogin: () => {},
  changePassword: async () => {},
  getNewToken: async () => {},
});

export const useAccount = () => useContext(AccountContext);

interface LocationState {
  from: string;
}

export const AccountProvider: FC = ({ children }) => {
  const history = useHistory();
  const location = useLocation<LocationState>();
  const { addToast } = useToasts();
  const [silentLoginPassed, setSilentLoginPassed] = useState(false);
  const refreshTimerRef = useRef<number>();
  const { t } = useTranslation();
  const client = useApolloClient();
  const [hasFetchedNewToken, setHasFetchedNewToken] = useState(false); // Used to trigger/track state change for impersonation

  const handleSilentRefresh = useCallback((query, refreshInterval) => {
    if (refreshInterval !== 0) {
      refreshTimerRef.current = window.setTimeout(() => {
        query();

        // End impersonation session if the cookie has expired
        // We use undefined here to differentiate between the cookie expiring and the user ending impersonation
        if (
          isImpersonatingActive(false) === undefined &&
          getUnimpersonateURL()
        ) {
          window.location.href = getUnimpersonateURL();
        }
      }, refreshInterval);
    }
  }, []);

  const onError = (error: { message: string }) => {
    addToast({ type: 'error', message: error.message });
  };

  const onSuccess = (
    data:
      | Login_login_LoginSuccess
      | RefreshToken_refreshToken
      | Register_register_RegisterSuccess,
    redirectTo?: string,
  ) => {
    try {
      const { token } = data;

      if (token) {
        // Persist refresh token through browser refresh
        setTokens(token);

        // Set access token to reactive var inMemory storage
        setToken(token);

        const refreshInterval = getTokenRefreshInterval(token);

        // Start silent refresh flow
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        handleSilentRefresh(refreshQuery, refreshInterval);

        if (redirectTo) {
          // Redirect to desired page
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          history.push(redirectTo);
        }
      }
    } catch (e) {
      // Show error message
      onError(e as { message: string });
    }
  };

  const [refreshQuery] = useMutation(REFRESH_MUTATION, {
    onCompleted: async (data) => {
      if (data.refreshToken) {
        await onSuccess(data.refreshToken);
      }
    },
    onError: () => {
      setToken(null);
    },
  });

  const [loginQuery] = useMutation(LOGIN_QUERY, {
    onCompleted: (data) => {
      if (data.login?.__typename === 'LoginSuccess') {
        onSuccess({
          ...data.login,
          token: JSON.parse(data.login.token),
        });
      } else if (data.login?.__typename === 'LoginFailed') {
        addToast({
          type: 'error',
          message: t('account.invalidUserCredentials'),
        });
      }
    },
    onError: (error: { message: string; status?: number }) => {
      let { message } = error;

      // TODO: implement status when it is available
      switch (message) {
        case 'User does not exist':
          message = t('account.userDoesNotExist');
          break;
        case '401: Invalid user credentials':
          message = t('account.invalidUserCredentials');
          break;
        default:
          message = t('account.somethingWentWrong');
          break;
      }

      addToast({ type: 'error', message });
    },
  });

  const [registerQuery] = useMutation(REGISTER_QUERY, {
    onCompleted: (data) => {
      if (data.register?.__typename === 'RegisterSuccess') {
        onSuccess({
          ...data.register,
          token: JSON.parse(data.register.token),
        });
      } else if (
        data.register?.__typename === 'EmailInvalid' &&
        data.register.issue === InvalidEmailPlain.EMAIL_ALREADY_IN_USE
      ) {
        addToast({ type: 'error', message: t('account.accountAlreadyExists') });
      } else if (
        data.register?.__typename === 'EmailInvalid' &&
        data.register.issue === InvalidEmailPlain.EMAIL_NOT_SUPPORTED_FOR_STAFF
      ) {
        addToast({ type: 'error', message: t('account.staffEmailNotAllowed') });
      } else {
        addToast({
          type: 'error',
          message: t('account.somethingWentWrong'),
          detail: data,
        });
      }
    },
    onError: () => {
      addToast({ type: 'error', message: t('account.somethingWentWrong') });
    },
  });

  const [logoutQuery] = useMutation<
    { logout: TLogoutQueryResult },
    TLogoutQueryVars
  >(LOGOUT_QUERY, {
    onCompleted: () => {
      // Cancel silentRefresh flow
      clearTimeout(refreshTimerRef.current);

      // todo - remove setTokens when cookies will be ready
      setTokens(null);

      // Remove access token & clear userinfo
      setToken(null);

      // Clear Apollo cache without refetching
      client.clearStore();
    },
    onError,
  });

  const [changePasswordMutation] = useMutation(CHANGE_PASSWORD_MUTATION, {
    onCompleted: (data) => {
      const typename = data.changePassword?.__typename;

      if (typename === 'Success') {
        addToast({ type: 'success', message: t('account.changedPassword') });
        history.push(`${PAGES.MY}${PAGES.SETTINGS}`);
      } else if (typename === 'OldPasswordInvalid') {
        addToast({ type: 'error', message: t('account.incorrectOldPassword') });
      } else if (typename === 'PasswordInvalid') {
        addToast({ type: 'error', message: t('account.invalidNewPassword') });
      }
    },
    onError,
  });

  const login = useCallback(
    async (input: TLoginQueryVars) => {
      await loginQuery({ variables: input });
    },
    [loginQuery],
  );

  const logout = useCallback(async () => {
    // TODO - update this when cookies will be ready
    await logoutQuery({
      variables: {
        refreshToken: getTokenLocalStorage(),
      },
    }).then(() => setIsImpersonatingActive(false));
  }, [logoutQuery]);

  const register = useCallback(
    async (input: TRegisterQueryVars) => {
      await registerQuery({ variables: input });
    },
    [registerQuery],
  );

  const changePassword = useCallback(
    async (data: TChangePasswordQueryVars) => {
      await changePasswordMutation({ variables: { ...data } });
    },
    [changePasswordMutation],
  );

  const redirectToLogin = useCallback(() => {
    history.push({
      pathname: `${PAGES.ACCOUNT}${PAGES.LOGIN}`,
      state: { from: location.pathname },
    });
  }, [history, location]);

  const getNewToken = useCallback(async () => {
    await refreshQuery().then(() => setHasFetchedNewToken(true));
  }, [refreshQuery]);

  const isAuthenticated = useCallback(() => isTokenValid(), []);
  const isImpersonating = useCallback(() => isImpersonatingActive(), []);
  const user = useUserInfo(isAuthenticated());

  useEffect(() => {
    const refreshToken = getTokenLocalStorage();

    async function silentLogin() {
      await refreshQuery();
    }

    if (refreshToken) {
      silentLogin().then(() => {
        setSilentLoginPassed(true);
      });
    } else {
      setSilentLoginPassed(true);
    }
  }, [refreshQuery, hasFetchedNewToken]);

  if (!silentLoginPassed) {
    return <Loader fullScreen />;
  }

  return (
    <AccountContext.Provider
      value={{
        isAuthenticated,
        user,
        login,
        logout,
        register,
        redirectToLogin,
        changePassword,
        getNewToken,
        isImpersonating,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};
