/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * An enumeration.
 */
export enum InvalidEmailPlain {
  EMAIL_ALREADY_IN_USE = "EMAIL_ALREADY_IN_USE",
  EMAIL_NOT_SUPPORTED_FOR_STAFF = "EMAIL_NOT_SUPPORTED_FOR_STAFF",
  EMAIL_NOT_VALID = "EMAIL_NOT_VALID",
  EMAIL_REQUIRED_FIELD_MISSING = "EMAIL_REQUIRED_FIELD_MISSING",
}

/**
 * An enumeration.
 */
export enum ResultSeverity {
  ELEVATED_RISK = "ELEVATED_RISK",
  HIGH_RISK = "HIGH_RISK",
  INCONCLUSIVE = "INCONCLUSIVE",
  NEGATIVE = "NEGATIVE",
  POSSIBLE_RISK = "POSSIBLE_RISK",
}

/**
 * An enumeration.
 */
export enum TestStatus {
  CANCELLED = "CANCELLED",
  OTHER = "OTHER",
  RELEASED = "RELEASED",
  REPORTED = "REPORTED",
}

/**
 * Input data for creating a consult
 */
export interface ConsultInput {
  participant?: ParticipantInput | null;
  counselorId?: string | null;
  phone: string;
  email: string;
  topic: string;
  state: string;
  onDemand?: boolean | null;
  isInterpreterRequested?: boolean | null;
  language?: string | null;
  info?: string | null;
  bookedSlot?: any | null;
  timezone?: string | null;
  customerprofileId?: string | null;
  myriadAccessionId?: string | null;
}

/**
 * Input data for updating a consult
 */
export interface ConsultUpdateInput {
  counselorId?: string | null;
  phone?: string | null;
  timezone?: string | null;
  bookedSlot?: any | null;
}

export interface ParticipantInput {
  firstName: string;
  lastName: string;
  dob: any;
}

export interface ProfileInputType {
  firstName?: string | null;
  lastName?: string | null;
  phoneNumber?: string | null;
  timezone?: string | null;
  email?: string | null;
}

export interface VerifyOTPInput {
  phoneNumber?: string | null;
  oneTimePassword?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  dateOfBirth?: any | null;
}

export interface VerifyPersonalInfoInput {
  firstName?: string | null;
  lastName?: string | null;
  dateOfBirth?: any | null;
}

export interface VerifyWithSmsInput {
  phone?: string | null;
  deliveryMethod?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  dateOfBirth?: any | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
